export default [
    [
        29,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        30,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        31,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        34,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        35,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        36,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        39,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        40,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        41,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        44,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        45,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        46,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        49,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        50,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        51,
        {
            type: 'histogram',
            universe_total_idx: 198,
        },
    ],
    [
        91,
        {
            type: 'histogram',
            universe_total_idx: 90,
        },
    ],
    [
        92,
        {
            type: 'histogram',
            universe_total_idx: 90,
        },
    ],
    [
        93,
        {
            type: 'histogram',
            universe_total_idx: 90,
        },
    ],
] as const
